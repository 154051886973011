import React from 'react';

export default () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="28" height="28" rx="14" stroke="#181833" strokeWidth="2"/>
      <rect x="13" y="21" width="4" height="4" rx="2" fill="#181833"/>
      <path
        d="M13.0665 6.99413C13.0301 5.90372 13.9042 5 14.9952 5V5C16.0899 5 16.9654 5.90947 16.9237 7.00331L16.5033 18.0381C16.4829 18.5752 16.0415 19 15.504 19H14.4339C13.8946 19 13.4524 18.5723 13.4344 18.0333L13.0665 6.99413Z"
        fill="#181833"/>
    </svg>
  );
};
